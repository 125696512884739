@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.4s; /* Firefox < 16 */
  -ms-animation: fadein 0.4s; /* Internet Explorer */
  -o-animation: fadein 0.4s; /* Opera < 12.1 */
  animation: fadein 0.4s;
  transition-property: all;
  -moz-transition-property: all; /* Firefox 4 */
  -webkit-transition-property: all; /* Safari and Chrome */
  -o-transition-property: all; /* Opera */

  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s; /* Firefox 4 */
  -webkit-transition-duration: 0.2s; /* Safari and Chrome */
  -o-transition-duration: 0.2s; /* Opera */

  transition-timing-function: linear;
  -moz-transition-timing-function: linear; /* Firefox 4 */
  -webkit-transition-timing-function: linear; /* Safari and Chrome */
  -o-transition-timing-function: linear; /* Opera */

  transition-delay: 0.1s;
  -moz-transition-delay: 0.1s; /* Firefox 4 */
  -webkit-transition-delay: 0.1s; /* Safari and Chrome */
  -o-transition-delay: 0.1s; /* Opera */
}

$indigo-dye: #274060ff;
$bdazzled-blue: #335c81ff;
$french-sky-blue: #65afffff;
$space-cadet: #1b2845ff;
$blue-gray: #5899e2ff;

button.navbar-toggler {
  background: #edf0f5 !important;
}

.noticeboardcard {
  .card-header {
    background-color: $indigo-dye;
    color: honeydew;
    button {
      background-color: $blue-gray !important;
      color: black !important;
    }
  }
}

.layoutbuttons {
  button {
    background: #00000000 !important;
    outline: none;
    border: none;
    img {
      height: 30px;
      width: auto;
    }
  }
}

.layoutbuttonsicon {
  height: 30px;
  width: auto;
}
.boardlayoutnav {
  .nav-item {
    .nav-link.active {
      background-color: transparent !important;
      border: 5px solid $indigo-dye !important;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
