/* @import "~bootstrap/scss/bootstrap"; */
$indigo-dye: #274060ff;
$bdazzled-blue: #335c81ff;
$french-sky-blue: #65afffff;
$space-cadet: #1b2845ff;
$blue-gray: #5899e2ff;

$theme-colors: (
  "primary": $space-cadet,
);

@import "~bootstrap/scss/bootstrap.scss";

body {
  height: 100vh;
  background: #edf0f5 !important;
}

nav {
  a {
    color: $space-cadet !important;
  }
}

.navbar-nav {
  position: relative;
  a {
    color: $space-cadet !important;
    padding: 10px 15px !important;
    margin: auto 2;
    border-radius: 20px;
    transition: all 0.8s;
    text-align: center;
    font-weight: 500;
    svg {
      margin-right: 5px;
    }
  }
  a:hover,
  .active {
    background: $space-cadet;
    color: white !important;
  }
}

a {
  color: $bdazzled-blue !important;
}

// button {
//   background: $indigo-dye !important;
//   color: white !important;
// }

// .table {
//   thead {
//     th,
//     td {
//       border-color: $indigo-dye !important;
//     }
//   }
//   td {
//     border-color: $indigo-dye !important;
//   }
// }

.buttonoff {
  background: red !important;
  color: white !important;
}

.buttonon {
  background: rgb(20, 218, 96) !important;
  color: white !important;
}
